<template>
    <div class='function-template'>
        <div class="table-header">
            <categorySearch :source="filterSource" class="searchBlock" @change="initTableData" />
            <a-button type="primary" @click="handleAdd">添加</a-button>
        </div>
        <basic-table ref="tableRef" class="template-table" v-bind="tableConfig" @page-change="pageChange">
            <template #slotUnit="{ row }">
                <span>{{ row.unit || '/' }}</span>
            </template>
        </basic-table>
        <component :is="modal" :isEditing="isEditing" :visible="visible" :loading="loading" :editData="editData"
            :active-tab="tab" :codeUniqueFunction="apiFunctionCodeUnique" @ok="ok" @cancel="cancel"></component>
    </div>
</template>


<script setup>
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from '@/components/basic/table'
import { computed, ref, onMounted, defineProps, nextTick } from 'vue'
import { message, Modal } from 'ant-design-vue';
import modalParams from '../../components/modalParams.vue';
import modalEvent from '../../components/modalEvent.vue';
import { apiFunctionCodeUnique } from '@/api/IoT/productCenter';

onMounted(() => {
    initTableData()
})

const props = defineProps({
    tab: Object
})

const modal = computed(() => {
    return (props.tab.value === '04' || props.tab.value === '05') ? modalEvent : modalParams
})

const filterSource = [
    {
        label: '功能名称',
        type: 'input',
        bindKey: 'functionName',
        placeholder: '请输入'
    },
    {
        label: '功能标识符',
        type: 'input',
        bindKey: 'identifier',
        placeholder: '请输入'
    },
]
const tableConfig = computed(() => {
    let tableConfig = {
        showIndex: false,
        showSelect: false,
        pagination: true,
        isPageBottom: true,
        remote: false, //!!!!!!!!!!!!!!!!!!!!!!!
        columns: [],
        scroll: {
            y: "calc(100vh - 340px)",
        },
        action: {
            title: "操作",
            fixed: "right",
            width: 100,
            items: [
                {
                    icon: "FormOutlined",
                    iconTip: '编辑',
                    type: "icon",
                    visibleFn: (row) => row.functionType === '03',
                    onClick: (row) => {
                        handleEdit(row);
                    },
                },
                {
                    icon: "DeleteOutlined",
                    iconTip: '删除',
                    type: "icon",

                    visibleFn: (row) => row.functionType === '03',
                    onClick: (row) => {
                        handleDelete(row.id);
                    },
                },
            ],
        },
    }
    if (props.tab.value === '01') {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
            },
            {
                title: "数据类型",
                type: "text",
                key: "dataTypeContent",
            },
            {
                title: "值约束",
                type: "text",
                key: "valueDescriptionContent",
            },
            {
                title: "单位",
                type: "customize",
                slotName: "slotUnit",
            },
        ]
    } else if (props.tab.value === '02' || props.tab.value === '03') {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
            },
            {
                title: "数据类型",
                type: "text",
                key: "dataTypeContent",
            },
            {
                title: "值约束",
                type: "text",
                key: "valueDescriptionContent",
            },
            {
                title: "读写",
                type: "text",
                key: "rwContent",
            },
            {
                title: "单位",
                type: "customize",
                slotName: "slotUnit",
            },
        ]
    } else {
        tableConfig.columns = [
            {
                title: "标识符",
                type: "text",
                key: "identifier",
                width: 150
            },
            {
                title: "名称",
                type: "text",
                key: "functionName",
                width: 150
            },
            {
                title: "类型",
                type: "text",
                key: "functionTypeContent",
                width: 150
            },
            {
                title: "事件参数",
                type: "text",
                key: "functionParameterContent",
                ellipsis: true
            },
            {
                title: "响应参数",
                type: "text",
                key: "responseParameterContent",
                ellipsis: true
            }
        ]
    }
    return tableConfig
})
const tableRef = ref();
const isEditing = ref(false)
const visible = ref(false)
const loading = ref(false)
const editData = ref({})


const initTableData = (searchItems = {}) => {
    props.tab.func.list({
        pageNo: tableRef.value.paginationProps.current,
        pageSize: tableRef.value.paginationProps.pageSize,
        ...searchItems
    }).then(({ result }) => {
        tableRef.value.dataSourceRef = result.records || [];
        tableRef.value.paginationProps.total = result.total;
    })

};
// eslint-disable-next-line no-unused-vars
const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex
    initTableData()
}

const handleAdd = () => {
    visible.value = true
}

const handleEdit = (row) => {
    visible.value = true;
    nextTick(() => {
        isEditing.value = true;
        editData.value = row
    })
}

const handleDelete = (id) => {
    Modal.confirm({
        title: '提醒',
        content: '请确认是否删除？',
        onOk() {
            return new Promise((resolve) => {
                props.tab.func.delete(id).then(res => {
                    if (res.errorCode === '200') {
                        message.success('删除成功');
                        initTableData()
                    }
                }).finally(() => {
                    resolve()
                })
            })
        }
    });
}

// eslint-disable-next-line no-unused-vars
const ok = m => {
    console.log(88888);
    loading.value = true
    let func = m.id ? 'edit' : 'add'
    props.tab.func[func](m).then(() => {
        message.success(`${m.id ? '修改' : '添加'}成功`)
        visible.value = false;
        initTableData()
    }).finally(() => {
        loading.value = false;
    })
}
// eslint-disable-next-line no-unused-vars
const cancel = () => {
    isEditing.value = false
    visible.value = false
}

</script>
<style lang='less' scoped>
.table-header {
    display: flex;
    justify-content: space-between;
}
</style>