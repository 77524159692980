<template>
  <div class="common-wrapper" id="commonwrapper">
    <a-tabs v-model:activeTab="activeTab">
      <a-tab-pane v-for="(tab) in tabs" :key="tab.value" :tab="tab.label">
        <function-template :tab="tab"  />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script setup>
import { ref, onMounted, provide } from 'vue'
import functionTemplate from './components/functionTemplate.vue';
import { apiFunctionProductParams, apiFunctionDeviceParams, apiFunctionDeviceAttribute, apiFunctionDeviceEvent, apiFunctionDeviceService } from "@/api/IoT/productCenter.js";
import { apiDict } from '@/api/dict';

const activeTab = ref('01')
const functionType = [{label:'标准可选功能',value:'03'}]
const dataType = ref([])
const unit = ref([])
const rwType = ref([])
const tabs = ref([])
const tabsFunc = [apiFunctionProductParams, apiFunctionDeviceParams, apiFunctionDeviceAttribute, apiFunctionDeviceEvent, apiFunctionDeviceService]
provide('provideParams', { functionType, dataType, unit, rwType })

onMounted(() => {
  // apiDict.selective('PRODUCT_FUNCTION_TYPE').then(({ result }) => {
  //   functionType.value = result.slice(0,3)
  // })
  apiDict.selective('PARAM_DATA_TYPE').then(({ result }) => {
    dataType.value = result
  })
  
  apiDict.selective('UNIT').then(({ result }) => {
    unit.value = result
  })
  apiDict.selective('RW_TYPE').then(({ result }) => {
    rwType.value = result
  })
  apiDict.selective('PRODUCT_FUNCTION_CATEGORY').then(({ result }) => {
    tabs.value = result.map((e, idx) => {
      return {
        ...e,
        func: tabsFunc[idx],
      }
    })
  })
})
</script>
<style lang='less' scoped>
.common-wrapper {
  position: relative;
  padding-top: 0;
}
.ant-tabs {
  height: calc(100vh - 120px);
}
</style>